/*eslint no-undef: "error"*/

import { Script, graphql } from "gatsby"
import queryString from "query-string"
import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import styled, { css } from "styled-components"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useEffect, useState } from "react"
import Head from "../components/Head"
import Layout from "../components/Layout"
import { LocalizedLink } from "../components/LocalizedLink"
import Header from "../components/checkout/Header"
import { BrinkContext } from "../components/context/BrinkContext"
import * as events from "../components/context/utils/events"
import Price from "../components/product/Price"
import { MEDIA_MIN_LARGE, MEDIA_MIN_MEDIUM } from "../constants"
import { withoutBreaklines } from "../helpers/string"
import { toDinero } from "../helpers/toDinero"
import { updateSearchParams } from "../helpers/url"
import useSanityTranslation from "../hooks/useSanityTranslation"

const Wrapper = styled.div``

const Body = styled.div`
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  background: ${(p) => p.theme.colors.background};

  ${MEDIA_MIN_LARGE} {
    margin: 0 auto;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  grid-template-rows: auto;
`

const Media = styled.div`
  grid-area: 1 / 1 / 1 / 17;

  ${MEDIA_MIN_LARGE} {
    grid-area: 1 / 1 / 1 / 9;
  }
`

const Image = styled(GatsbyImage)`
  width: 100%;
  display: block;
`

const Information = styled.div`
  grid-area: 2 / 1 / 2 / 17;
  padding-inline: 1.2rem;

  ${MEDIA_MIN_MEDIUM} {
    grid-area: 2 / 1 / 2 / 12;
  }
  ${MEDIA_MIN_LARGE} {
    margin-top: 0;
    grid-area: 1 / 10 / 1 / 15;
    padding-inline: 0;
  }
`

const Navigation = styled.ol`
  display: flex;
  gap: 2.4rem;
  padding: 0;
  margin: 0;
  margin-top: 1.2rem;
  margin-bottom: 2.4rem;

  ${MEDIA_MIN_LARGE} {
    margin-bottom: 6.7rem;
  }
`

const NavigationItem = styled.li`
  list-style: none;
  cursor: pointer;
  font-size: 2rem;
  line-height: 2.4rem;
  font-variation-settings: "wght" 450;
  color: ${(p) => p.theme.colors.darkGrey};

  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      a {
        text-decoration: line-through;
      }
    `}

  a {
    color: ${(p) =>
      p.active ? p.theme.colors.black : p.theme.colors.darkGrey};
  }
`

const Title = styled.h2`
  font-size: 2.6rem;
  line-height: 3rem;
  font-variation-settings: "wght" 500;
  margin-block: 1.2rem;
`

const Description = styled.p`
  font-size: 2rem;
  line-height: 2.4rem;
  font-variation-settings: "wght" 450;
  margin: 0;
`

const VariantSelector = styled.div`
  margin-top: 3.6rem;
  margin-bottom: 3.6rem;

  ${MEDIA_MIN_LARGE} {
    margin-top: 6.4rem;
    margin-bottom: 4.8rem;
  }
`

const VariantContainer = styled.div`
  padding: 1.2rem;
  cursor: pointer;
  display: flex;
  gap: 1.2rem;
  align-items: center;

  ${(p) =>
    p.active
      ? `border: 1px solid ${p.theme.colors.black};`
      : `border: 1px solid ${p.theme.colors.grey}; &:not(:first-child) { border-top: none; }`}
`

const VariantInformation = styled.div`
  width: 100%;
`

const VariantHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const VariantTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 2rem;
  line-height: 2.4rem;
  text-transform: initial;
  font-variation-settings: "wght" 500;
`

const VariantPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 7rem;
  align-items: flex-end;
  align-self: flex-start;
`

const VariantOldPrice = styled(Price)`
  text-transform: uppercase;
  text-decoration: line-through;
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${(p) => p.theme.colors.grey};
`

const VariantPrice = styled(Price)`
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2.4rem;
`

const VariantDescription = styled.p`
  margin: 0;
  margin-top: 0.5rem;
  padding: 0;
  font-size: 1.6rem;
  line-height: 2rem;
  font-variation-settings: "wght" 500;
`

const VariantImage = styled(GatsbyImage)`
  width: 6.6rem;
  height: 6.6rem;
`

const NextButton = styled(LocalizedLink)`
  display: inline-block;
  margin: 0;
  text-align: center;
  cursor: pointer;
  background: ${(p) => p.theme.colors.black};
  color: rgb(255, 255, 255);
  padding-inline: 1.2rem;
  padding-block: 1.1rem;
  width: 100%;
  border-radius: 0.3rem;

  ${MEDIA_MIN_LARGE} {
  }
`

const SubscriptionDescription = styled.ul`
  margin-block: 0;
  margin-top: 1.2rem;
`

const SubscriptionPlanSelector = styled.div`
  margin-block: 3.6rem;

  ${MEDIA_MIN_LARGE} {
    margin-block: 4.8rem;
  }
`

const SubscriptionItem = styled.div`
  width: 100%;
  padding: 1.2rem 1.2rem 1rem;
  ${(p) =>
    p.checked
      ? `border: 1px solid ${p.theme.colors.black};`
      : `border: 1px solid ${p.theme.colors.grey}; &:not(:first-child) { border-top: none; }`}
`

const RadioButton = styled.div`
  height: 1.8rem;
  width: 1.8rem;
  cursor: pointer;
  border-radius: 50%;
  transition: border 0.2s;
  position: relative;
  border: 0.1rem solid ${(p) => p.theme.colors.black};
  margin: 0 1rem 0.1rem 0;

  ${(p) =>
    p.checked &&
    css`
      &::after {
        background: ${(p) => p.theme.colors.black};
        content: "";
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`

const ItemName = styled.label`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2.4rem;
  height: 2.4rem;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;

    > label {
      margin-left: 1rem;
      pointer-events: none;
    }
  }
`

const ItemDescription = styled.span`
  font-size: 1.6rem;
  display: inline-block;
  margin-top: 0.5rem;
`

const Notice = styled.p`
  font-size: 1.6rem;
  line-height: 1.9rem;
  font-variation-settings: "wght" 450;

  a {
    color: ${(p) => p.theme.colors.black};
    font-variation-settings: "wght" 700;
  }
`

const UspContainer = styled.div`
  display: flex;
  gap: 1.2rem;
  margin-block: 1.6rem;
`

const UspChip = styled.span`
  font-size: 1.4rem;
  line-height: 1.5rem;
  color: ${(p) => p.theme.colors.black};
  background-color: ${(p) => p.theme.colors.lightGrey};
  border-radius: 0.2rem;
  padding: 0.4rem 0.8rem;
`

const GetStartedPage = ({ data: { sanityProduct }, pageContext, location }) => {
  const { languageCode, currentStore, getStocks } = useContext(BrinkContext)
  const [activeVariants, setActiveVariants] = useState([])
  const { t } = useTranslation("translations")
  const trans = useSanityTranslation()

  const { v, s } = queryString.parse(location.search)

  const variants =
    sanityProduct?.variants?.filter(
      (v) =>
        v.active &&
        !v.title.includes("[SUBSCRIPTION]") &&
        v.slug.current !== "intro-set-discovery"
    ) ?? []
  const [sanityProductVariant, setSanityProductVariant] = useState(variants[0])
  const [currentStep, setCurrentStep] = useState(1)
  const [frequency, setFrequency] = useState("")

  useEffect(() => {
    if (sanityProduct && sanityProduct.variants?.length > 0) {
      getStocks(sanityProduct.variants.map((v) => v._id))
        .then((stocks) => {
          setActiveVariants(
            sanityProduct?.variants
              ?.filter(
                (v) =>
                  v.active &&
                  !v.title.includes("[SUBSCRIPTION]") &&
                  v.slug.current !== "intro-set-discovery"
              )
              ?.filter((v) => {
                const stock = stocks.find((s) => s.productId === v._id)
                if (!stock) return false
                return stock.availableQuantity > 0
              }) ?? []
          )
        })
        .catch((error) => {
          console.error("error", error)
          setActiveVariants([])
        })
    } else {
      setActiveVariants([])
    }
  }, [sanityProduct])

  useEffect(() => {
    const variant = variants.find((variant) => variant._id === v) ?? variants[0]
    setSanityProductVariant(variant)
    setCurrentStep(s ? parseInt(s) : 1)
  }, [v, s])

  useEffect(() => {
    setFrequency(sanityProductVariant.subscriptionItems[0].recurlyPlanCode)
  }, [sanityProductVariant])

  const title = t("Get Started")

  const onStepChange = (step) => {
    setCurrentStep(step)
  }

  const onVariantClick = (variant) => {
    updateSearchParams(location, "v", variant._id)
  }

  const onFirstStepCompleted = () => {
    events.completeSubscriptionConfig(
      withoutBreaklines(sanityProductVariant.displayName.en)
    )
  }

  const onSecondStepCompleted = () => {
    events.completeSubscriptionConfig(
      withoutBreaklines(sanityProductVariant.displayName.en),
      frequency
    )
  }

  if (!sanityProductVariant) {
    return null
  }

  return (
    <Layout
      meta={{ title }}
      pageContext={pageContext}
      invertedHeader
      noHeaderBorder
    >
      <Script defer="" src="https://s.apprl.com/js/apprl.js" />
      <Wrapper className="top">
        <Helmet>
          <html lang="en" className="noOverflow" />
          <body className="noOverflow" />
        </Helmet>
        <Head
          meta={{
            title
          }}
        />
        <Header />
        <Body>
          <Grid>
            <Media>
              <Image
                image={getImage(
                  currentStep === 2
                    ? sanityProductVariant?.subscriptionConfigImage.asset
                    : sanityProductVariant?.mainImage.asset
                )}
                alt={sanityProductVariant?.displayName[languageCode] || ""}
              />
            </Media>
            <Information>
              <Navigation>
                <NavigationItem
                  onClick={() => onStepChange(1)}
                  active={currentStep === 1}
                  disabled={!sanityProductVariant?.subscriptionInitialFee}
                >
                  <LocalizedLink
                    to={`/product/${sanityProduct.slug.current}/get-started`}
                  >
                    {t("get-started-step-1")}
                  </LocalizedLink>
                </NavigationItem>
                <NavigationItem
                  onClick={() => onStepChange(2)}
                  active={currentStep === 2}
                >
                  <LocalizedLink
                    to={`/product/${sanityProduct.slug.current}/get-started?v=${sanityProductVariant?._id}&s=2`}
                  >
                    {t("get-started-step-2")}
                  </LocalizedLink>
                </NavigationItem>
                <NavigationItem>{t("get-started-step-3")}</NavigationItem>
              </Navigation>
              {currentStep === 1 && (
                <>
                  <Title>{t("choose-your-capsules")}</Title>
                  <Description>{t("get-started-page-description")}</Description>
                  <VariantSelector>
                    {activeVariants.map((variant) => (
                      <VariantContainer
                        key={variant._id}
                        active={sanityProductVariant._id === variant._id}
                        onClick={() => onVariantClick(variant)}
                      >
                        <VariantImage
                          image={getImage(variant.optionImage?.asset)}
                          alt={variant.displayName[languageCode]}
                        />
                        <VariantInformation>
                          <VariantHeading>
                            <VariantTitle>
                              {withoutBreaklines(trans(variant.displayName))}
                            </VariantTitle>
                          </VariantHeading>
                          <VariantDescription>
                            {trans(variant.highlight)}
                          </VariantDescription>
                        </VariantInformation>
                        <VariantPriceContainer>
                          <VariantOldPrice price={variant.price} multiply />
                          <VariantPrice
                            price={variant.subscriptionInitialFee}
                          />
                        </VariantPriceContainer>
                      </VariantContainer>
                    ))}
                  </VariantSelector>
                  <NextButton
                    onClick={onFirstStepCompleted}
                    to={`/product/${sanityProduct.slug.current}/get-started?v=${sanityProductVariant._id}&s=2`}
                  >
                    {t("Next step")}
                  </NextButton>
                  <UspContainer>
                    <UspChip>{t("Pause or cancel anytime")}</UspChip>
                    <UspChip>{t("30-day money-back guarantee")}</UspChip>
                  </UspContainer>
                </>
              )}
              {currentStep === 2 && (
                <>
                  <Title>{t("select-a-subscription-plan")}</Title>
                  <SubscriptionDescription>
                    <li>
                      {t("get-started-capsule-frequency", {
                        price: `${toDinero(
                          parseInt(
                            sanityProductVariant.subscriptionVariant.price[
                              currentStore.currencyUnit
                            ]
                          ) * 100,
                          currentStore.currencyUnit
                        ).toFormat()}`
                      })}
                    </li>
                    <li>{t("always-free-delivery")}</li>
                    <li>{t("skip-pause-cancel-anytime")}</li>
                    <li>{t("save-x-%", { value: "15" })}</li>
                  </SubscriptionDescription>
                  <SubscriptionPlanSelector>
                    {sanityProductVariant.subscriptionItems.map(
                      (item, index) => (
                        <SubscriptionItem
                          key={index}
                          checked={frequency === item.recurlyPlanCode}
                        >
                          <ItemName
                            onClick={() => {
                              setFrequency(item.recurlyPlanCode)
                            }}
                          >
                            <div>
                              <RadioButton
                                checked={frequency === item.recurlyPlanCode}
                              />
                              {item.title[languageCode] ?? item.title.en}
                            </div>
                          </ItemName>
                          <ItemDescription>
                            {item.subtitle[languageCode] ?? item.subtitle.en}
                          </ItemDescription>
                        </SubscriptionItem>
                      )
                    )}
                  </SubscriptionPlanSelector>
                  <NextButton
                    onClick={onSecondStepCompleted}
                    to={`/product/${sanityProduct.slug.current}/subscribe?v=${sanityProductVariant?._id}&s=${frequency}`}
                  >
                    {t("Next step")}
                  </NextButton>
                  <Notice>
                    {t("Want to buy the Intro Set without subscribing?")}{" "}
                    <LocalizedLink
                      to={`/product/${sanityProduct.slug.current}?variant=${sanityProductVariant?._id}`}
                    >
                      {t("Click here to explore your options.")}
                    </LocalizedLink>
                  </Notice>
                </>
              )}
            </Information>
          </Grid>
        </Body>
      </Wrapper>
    </Layout>
  )
}

export default GetStartedPage

export const query = graphql`
  query ($sanityProductId: String!) {
    sanityProduct(_id: { eq: $sanityProductId }) {
      ...Product
    }
  }
`

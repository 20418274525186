import React, { useContext } from "react"
import styled from "styled-components"
import { BrinkContext } from "../context/BrinkContext"
import { toDinero, toDineroFormatted, toFormat } from "../../helpers/toDinero"

const Prices = styled.div`
  display: flex;
  font-size: 1.4rem;
`

const CurrentPrice = styled.span`
  display: block;
`

const OriginalPrice = styled.span`
  color: ${(p) => p.theme.colors.darkGrey};
  text-decoration: line-through;
  margin-left: 0.5rem;
`

const getDiscountPrice = (discount, currencyUnit, priceAmount) => {
  const discountMoney = toDinero(discount.amount, currencyUnit)
  const price = toDinero(priceAmount, currencyUnit)
  return price.subtract(discountMoney)
}

const Price = ({ price, allDiscount = null, multiply = false, ...rest }) => {
  const { currentStore } = useContext(BrinkContext)
  const { currencyUnit } = currentStore

  if (!price) {
    return null
  }

  let formattedPrice = []
  if (!Array.isArray(price)) {
    formattedPrice = Object.keys(price).map((key) => {
      return {
        currencyUnit: key,
        amount: parseInt(price[key]) * (multiply ? 100 : 1)
      }
    })
  } else {
    formattedPrice = multiply ? price.map((p) => p.amout * 100) : price
  }

  const priceAmount = formattedPrice.find(
    (p) => p.currencyUnit === currencyUnit
  )
    ? formattedPrice.find((p) => p.currencyUnit === currencyUnit).amount
    : 0
  const discount =
    allDiscount && allDiscount.find((d) => d.currencyUnit === currencyUnit)
  const priceWithDiscount =
    discount && getDiscountPrice(discount, currencyUnit, priceAmount)

  return (
    <Prices {...rest}>
      {discount && discount.amount !== 0 ? (
        <>
          <CurrentPrice>{toFormat(priceWithDiscount)}</CurrentPrice>{" "}
          <OriginalPrice>
            {toDineroFormatted(priceAmount, currencyUnit)}
          </OriginalPrice>
        </>
      ) : (
        <CurrentPrice>
          {toDineroFormatted(priceAmount, currencyUnit)}
        </CurrentPrice>
      )}
    </Prices>
  )
}

export default Price
